import React, { useState } from "react";
import BoxTypo from "../components/BoxTypo";
import { Box, Button, Slider, Stack } from "@mui/material";
import "../style/base.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { nl2br } from "../functions/nl2br";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import "../style/font-style.css";
// import "../style/mui-override.css";
import { StaticImage } from "gatsby-plugin-image";
import { StyledEngineProvider } from "@mui/material/styles";
import questions, { kins, ansType } from "../data/questions";

const QuestionCard = (props) => {
  const setIndexFunc =
    props.questionNumber == props.totalLength - 1
      ? () => {
          props.setHiddenResult((c) => false);
        }
      : () => {
          props.setIndex((c) => c + 1);
        };
  const createResult = () => {
    /*
    1.回答番号と配列の順番から、ポジティブプラン配列を作るとネガティブプラン配列 
    2.ポジティブプランから、ネガティブプランに含んでいる数分だけひく。ただし、ポジティブプランにない場合は0のまま
    3.もっとも多いポジティブプランを選定。同率一位の場合はランダムで選択する
    */
    const ans = props.ans;
    let positiveList = [];
    let negativeList = [];
    questions.map((c, i) => {
      //配列を作る箇所をやる
      // console.log("チョイス=", c.choices[ans[i]], c.text);
      // console.log(c.choices[ans[i]].positive);
      positiveList = positiveList.concat(c.choices[ans[i]].positive);
      negativeList = negativeList.concat(c.choices[ans[i]].negative);
    });
    // console.log("create List=", positiveList, negativeList);
    negativeList.map((c, i) => {
      const posIndex = positiveList.findIndex((t) => t.name === c.name);
      if (posIndex > -1) {
        positiveList = positiveList.filter((_, index) => index !== posIndex);
      }
    });
    const tempList =
      positiveList[Math.floor(Math.random() * positiveList.length)]?.id;
    if (tempList === undefined) {
      return 1;
    }
    return tempList;
  };
  return (
    <Box className="w100 mw600 tac ma pr" height={"75vh"}>
      {props.type === "start" ? (
        <></>
      ) : (
        <Box>
          <BoxTypo
            fs={["18px", "22px"]}
            ls={["2px"]}
            lh={["200%"]}
            color="#4A4A4A"
          >
            Q{props.questionNumber}
          </BoxTypo>
          <Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
              justifyContent="center"
            >
              <Slider
                value={(props.questionNumber / props.totalLength) * 100}
                size="small"
                disabled={true}
                style={{
                  width: "40%",
                  height: "4px",
                  maxWidth: "300px",
                  color: "#DECFA8",
                }}
              />
            </Stack>
          </Box>
          <BoxTypo
            fs={["14px", "18px"]}
            lh={["200%"]}
            height="60px"
            mt={8}
            color="#4A4A4A"
          >
            {nl2br(props.question)}
          </BoxTypo>
        </Box>
      )}

      <Box>
        {props.type === "start" ? (
          <DiagnosisStart
            choices={props.choices}
            setIndex={setIndexFunc}
            label={props.label}
          />
        ) : props.type === "pullDown" ? (
          <PullDown
            choices={props.choices}
            setIndex={setIndexFunc}
            ans={props.ans}
            label={props.label}
          />
        ) : props.type === "buttonGroup" ? (
          <ButtonGroup
            className="w100"
            setIndex={setIndexFunc}
            ans={props.ans}
            choices={props.choices}
          />
        ) : (
          <RadioButtonGroup
            className="w100 "
            setIndex={setIndexFunc}
            ans={props.ans}
            choices={props.choices}
          />
        )}
      </Box>
      <Box className="pa b0" style={{ left: "10%" }} borderRadius={4}>
        <Button
          style={{ display: props.questionNumber <= 1 ? "none" : "block" }}
          onClick={() => {
            props.setIndex((c) => (c > 0 ? c - 1 : 0));
            props.setHiddenResult(true);
          }}
          value={false}
        >
          <Stack alignItems={"center"} justifyContent={"center"}>
            <ArrowBackIosOutlinedIcon style={{ color: "#DECFA8" }} />
            <BoxTypo fs={["12px"]} ls={["2px"]} lh={["200%"]} color="#DECFA8">
              Back
            </BoxTypo>
          </Stack>
        </Button>
      </Box>
      <Box className="pa b0" style={{ right: "10%" }}>
        <Button
          disabled={props.hiddenResult}
          onClick={() => {
            window.scroll({ top: 0, behavior: "auto" });
            console.log("結果=", createResult());
            window.location.href =
              window.location.origin + "/result?id=" + createResult();
          }}
          style={{
            display:
              props.questionNumber < props.totalLength - 1 ? "none" : "block",
            boxShadow: "none",
            backgroundColor: props.hiddenResult ? "#F5EFE6" : "#DECFA8",
            height: "90px",
            width: "90px",
            borderRadius: "90px",
          }}
          variant="contained"
          value={false}
        >
          <BoxTypo
            fs={["12px"]}
            ls={["2px"]}
            lh={["200%"]}
            color={props.hiddenResult ? "#CFCFCF" : "#fff"}
          >
            診断結果
          </BoxTypo>
        </Button>
      </Box>
    </Box>
  );
};

const ButtonGroup = (props) => {
  return (
    <Box className="w100 mt64">
      {props.choices.map((c, i) => {
        return (
          <Button
            key={"index" + i}
            variant="outlined"
            className="w90 mw300"
            onClick={() => {
              props.setIndex();
              props.ans?.push(i);
            }}
            style={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              height: "60px",
              border: "none",
              color: "#4A4A4A",
              margin: "auto",
              marginTop: "16px",
              display: "block",
            }}
          >
            <BoxTypo fs={["14px"]} ls={["2px"]} lh={["180%"]}>
              {c.text}
            </BoxTypo>
          </Button>
        );
      })}
    </Box>
  );
};

const RadioButtonGroup = (props) => {
  return (
    <FormControl className="w80 ma mw600 tac" style={{ marginTop: "128px" }}>
      <RadioGroup
        row
        aria-label="gender"
        defaultValue={props.choices[0].text}
        name="radio-buttons-groups"
        className="ma tac w90 "
        style={{ justifyContent: "space-between" }}
      >
        {props.choices.map((c, i) => {
          return (
            <FormControlLabel
              key={i}
              value={c.text}
              onClick={() => {
                props.setIndex();
                props.ans.push(i);
              }}
              style={{ fontFamily: "serif" }}
              control={
                <Radio
                  size="large"
                  fontSize="30px"
                  fontFamily="serif"
                  fontWeight="500"
                  style={{
                    border: "none",
                    color: "#DECFA8",
                    fontFamily: "serif",
                    fontWeight: "500",
                  }}
                />
              }
              label={c.text}
              labelPlacement="bottom"
              className="cblk"
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const PullDown = (props) => {
  const [value, setValue] = useState("選択してください");
  return (
    <Box className="w80 mw600 ma tac pr">
      <FormControl className="w100 mw300" style={{ marginTop: "30%" }}>
        <StyledEngineProvider injectFirst>
          <Select
            labelId="demo-simple-select-label"
            id="select-mui"
            name="test"
            value={value}
            style={{
              color: "#000",
              backgroundColor: "#fff",
              border: "none",
              outline: "none",
              lineHeight: "100%",
              height: "60px",
              padding: 0,
              fontSize: "12px",
            }}
            sx={{
              border: "1px solid #D8B04A",
              color: "#fff",
              outline: "none",
              "&.Mui-focused ": {
                // border: "2px solid #D8B04A",
                border: "2px solid #D8B04A",
                outline: "none !important",
                appearance: "none !important",
              },
              "& .MuiInputBase-root-MuiOutlinedInput-root": {
                border: "none",
                appearance: "none",
                outline: "none",
              },
              "& .Mui-focused": {
                border: "none",
                appearance: "none",
                outline: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                appearance: "none",
                outline: "none",
              },
            }}
            onChange={(e) => {
              setValue(e.target.value);
              console.log({ "e.target": e });
              props.setIndex((c) => c + 1);
              props.ans?.push(e.target.value);
            }}
          >
            {props.choices.map((c, i) => {
              return (
                <MenuItem value={i} name={i} key={i} className="m0 p0">
                  <BoxTypo
                    key={i}
                    fs={["12px"]}
                    ls={["2px"]}
                    lh={["100%"]}
                    m={2}
                    p={0}
                  >
                    {c.text}
                  </BoxTypo>
                </MenuItem>
              );
            })}
          </Select>
        </StyledEngineProvider>
      </FormControl>
    </Box>
  );
};

// const DiagnosisStarts = (props) => {
//   return (
//     <Box className="w100  db ma tac">
//       <Stack className="w100 tac ma" alignItem="center">
//         <StaticImage
//           alt="img"
//           src="../images/ForuGranola.png"
//           className="w50 mw600 ma mt128"
//         />
//         <BoxTypo
//           fs={["12px"]}
//           ls={["4px"]}
//           lh={["180%"]}
//           color={"#4A4A4A"}
//           mt={"8px"}
//         >
//           2分間のグラノーラ診断
//         </BoxTypo>
//         <Box className="mt64">
//           <Button
//             variant="outlined"
//             className="w80 mw300 ma tac"
//             style={{
//               color: "#fff",
//               border: "solid 1.5px #DECFA8",
//               backgroundColor: "#DECFA8",
//               height: "60px",
//               borderRadius: "10px",
//             }}
//             onClick={props.setIndex}
//           >
//             <BoxTypo fs={["14px"]} ls={["2px"]} lh={["100%"]}>
//               今すぐ診断スタート　→
//             </BoxTypo>
//           </Button>
//           {/* <a
//             to="https://forucafe.shop/"
//             style={{
//               marginTop: "-150px",
//               display: "block",
//               zIndex: "99",
//             }}
//           >
//             <BoxTypo fs={["10px"]} ls={["4px"]} lh={["180%"]} color={"#4A4A4A"}>
//               診断せずに自分で選ぶ
//             </BoxTypo>
//           </a> */}
//         </Box>
//       </Stack>
//     </Box>
//   );
// };

const DiagnosisStart = (props) => {
  return (
    <Box mt={"50%"}>
      <StaticImage
        alt="img"
        src="../images/ForuGranola.png"
        className="w60 mw600 ma"
      />
      <BoxTypo
        fs={["12px"]}
        ls={["4px"]}
        lh={["180%"]}
        color={"#4A4A4A"}
        mt={"8px"}
      >
        2分間のグラノーラ診断
      </BoxTypo>
      <Box mt="30%">
        <Button
          variant="outlined"
          className="w80 mw300 ma tac"
          style={{
            color: "#fff",
            border: "solid 1.5px #DECFA8",
            backgroundColor: "#DECFA8",
            height: "60px",
            borderRadius: "10px",
          }}
          onClick={props.setIndex}
        >
          <BoxTypo fs={["14px"]} ls={["2px"]} lh={["100%"]}>
            今すぐ診断スタート　→
          </BoxTypo>
        </Button>
      </Box>
    </Box>
  );
};

export default QuestionCard;

import React, { useState, useCallback, useEffect } from "react";
import {
  useTransition,
  animated,
  useSpringRef,
  easings,
} from "@react-spring/web";
import Box from "@mui/material/Box";
import styles from "../style/diagnosiss.css";
import QuestionCard from "../components/QuestionCard";
import { Helmet } from "react-helmet";
import Head from "../components/Head";
import { easing } from "@mui/material";
import BoxTypo from "../components/BoxTypo";
/********/
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import questionData from "../data/questions";
import questions, { kins, ansType } from "../data/questions";
import { useWindowDimensions } from "../functions/useWindowDimensions";
/********/
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const granolaType = {
  typeA: { id: 1, name: "heel" },
  typeB: "typeB",
  typeC: "",
};

const Diagnosis = () => {
  const [index, setIndex] = useState(0);
  const [hiddenResult, setHiddenResult] = useState(true);
  const [isForward, setForward] = useState(true);
  const transRef = useSpringRef();
  const backTransRef = useSpringRef();
  const fromTransform3d = "translate3d(100%,0,0)";
  const leaveTransform3d = "translate3d(-50%,0,0)";

  const switchDirection = () => {};
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    reverse: true,
    // config: { duration: 1500, easing: easing.easeInOutBounce },
    config: {
      duration: 1000,
      easing: easings.easeInOutQuad,
    },
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: {
      opacity: 0,
    },
  });
  useEffect(() => {
    transRef.start();
  }, [index]);
  //スタート画面用の初期値
  const [ans, setAns] = useState([0]);
  const { width, height } = useWindowDimensions();
  return (
    <Box bgcolor={"#F5EFE6"} height={"100lvh"}>
      <Head />
      {/* className={`flex fill container`} */}
      <div className={`flex fill container`} style={{ overflow: "hidden" }}>
        {transitions((style, i) => {
          console.log({ style });
          return (
            <animated.div
              key={i}
              style={{
                ...style,
                backgroundColor: "#F5EFE6",
                cursor: "default",
              }}
            >
              <QuestionCard
                type={questions[i].type}
                choices={questions[i].choices}
                label={questions[i].label}
                question={questions[i].text}
                questionNumber={i}
                setIndex={setIndex}
                totalLength={questions.length}
                hiddenResult={hiddenResult}
                setHiddenResult={setHiddenResult}
                setAns={setAns}
                ans={ans}
              />
            </animated.div>
          );
        })}
      </div>
    </Box>
  );
};

export default Diagnosis;

export const kins = {
  nuts: {
    name: "たっぷりナッツ",
    kins: "どのような食べ方でもどんなシーンでもお楽しみいただける、定番のグラノーラです。ナッツの⾹ばしさとメープルシロップの⾹りをお楽しみいただけるシンプルな味つけです。 4種類のナッツがゴロゴロとたくさん⼊っているので、⾷感も楽しく、⾷べ応えもあります。",
  },
  cranberry: {
    name: "クランベリー",
    kins: "メープルシナモンベースのグラノーラにたっぷりのクランベリーを加えた当店大人気の一品。ジューシーで甘酸っぱいクランベリーがフォルグラノーラの美味しさを引き立てます。",
  },
  cinnamon: {
    name: "シナモンオリジナル",
    kins: "上品な甘さとスパイシーさを持ち合わせるシナモンベースのグラノーラ。食感の楽しいカリカリのアーモンドと、ほんのり苦味のあるくるみがシナモンのエキゾティックな香りと相性抜群です。",
  },
  recommend: {
    name: "スタッフのおすすめコース",
    kins: "毎回スタッフのおすすめの商品をお送りします。「この商品を毎回送ってほしい」「この商品は避けてほしい」などのご要望も、可能な限り対応いたします。季節限定商品をお届けすることもあり、様々な商品を試してみたい方におすすめです。",
  },
  chocolate: {
    name: "チョコレートバナナ",
    kins: "芳醇で優しいカカオの香りが広がるグラノーラに、パリパリのバナナチップをトッピング。ほのかにビターなカカオの香りが、オートミールの甘い香りと旨味を引き立てます。",
  },
  salad: {
    name: "サラダにかけるグラノーラ",
    kins: "サラダのトッピングとして使い勝手が良いように、甘じょっぱいお味に仕上げました。カシューナッツの食感もクセになります。サクッザクっとしたオートミールやナッツの食感と、じゅわっと広がるシロップの旨味がフレッシュなサラダと相性ピッタリ。いつものサラダが、ワンランクグレードアップします。",
  },
  nonSugar: {
    name: "ノンシュガーコース",
    kins: "お砂糖を一切使用せずメープルシロップのみで甘さをつけたフォルグラノーラ。噛めば噛むほどピュアメープルシロップの優しい甘さが広がります。メープルシロップのコクと、気高いシナモンの香りを感じられるリッチなグラノーラのセットです。",
  },
};

export const ansType = {
  pullDown: "pullDown",
  radioButton: "radioButton",
  buttonGroup: "buttonGroup",
  result: "result",
  start: "start",
};
export const plans = {
  nutsAndCinnamon: {
    id: 0,
    name: "ナッツ&シナモン",
    set: [kins.nuts, kins.cinnamon],
  },
  recommend: {
    id: 1,
    name: "スタッフのおすすめコース",
    set: [kins.recommend],
  },
  nutsAndChocolate: {
    id: 2,
    name: "ナッツ&チョコレート",
    set: [kins.nuts, kins.chocolate],
  },
  nutsAndCranberry: {
    id: 3,
    name: "ナッツ&クランベリー",
    set: [kins.nuts, kins.cranberry],
  },
  cinnamonAndChocolate: {
    id: 4,
    name: "シナモン&チョコレートバナナ",
    set: [kins.cinnamon, kins.chocolate],
  },
  cranberryAndChocolate: {
    id: 5,
    name: "クランベリー&チョコレートバナナ",
    set: [kins.cranberry, kins.chocolate],
  },
  cinnamonAndCranberry: {
    id: 6,
    name: "シナモン&クランベリー",
    set: [kins.cinnamon, kins.cranberry],
  },
  nutsAndSalad: {
    id: 7,
    name: "ナッツ&サラダ",
    set: [kins.nuts, kins.salad],
  },
  saladAndCinnamon: {
    id: 8,
    name: "サラダ&シナモンオリジナル",
    set: [kins.salad, kins.cinnamon],
  },
  nonSugar: {
    id: 9,
    name: "ノンシュガーコース",
    set: [kins.nonSugar],
  },
};
const questions = [
  {
    text: "スタート画面",
    type: ansType.start,
    label: "",
    choices: [{ text: "", negative: [], positive: [] }],
  },
  {
    text: "グラノーラをよく食べるシーンは？",
    type: ansType.buttonGroup,
    choices: [
      {
        text: "ヨーグルトと一緒に",
        negative: [],
        positive: [
          plans.nutsAndCinnamon,
          plans.nutsAndCranberry,
          plans.nonSugar,
        ],
      },
      {
        text: "ミルクと一緒に",
        negative: [],
        positive: [plans.saladAndCinnamon],
      },
      {
        text: "サラダのトッピングとして",
        negative: [],
        positive: [plans.nutsAndSalad],
      },
      {
        text: "何もかけずそのままで",
        negative: [],
        positive: [
          plans.nonSugar,
          plans.cinnamonAndChocolate,
          plans.nutsAndChocolate,
        ],
      },
    ],
  },
  {
    text: "あなたの好みのナッツは？",
    type: ansType.buttonGroup,
    choices: [
      {
        text: "くるみ",
        negative: [],
        positive: [
          plans.cinnamonAndCranberry,
          plans.nonSugar,
          plans.cinnamonAndChocolate,
        ],
      },
      {
        text: "カシューナッツ",
        negative: [],
        positive: [plans.saladAndCinnamon, plans.nutsAndSalad],
      },
      {
        text: "マカダミアナッツ",
        negative: [],
        positive: [
          plans.nutsAndCinnamon,
          plans.nutsAndCranberry,
          plans.nutsAndChocolate,
        ],
      },
      { text: "思い浮かばない", negative: [], positive: [] },
    ],
  },
  {
    text: "ドライフルーツ入りの\nグラノーラは好きですか？",
    type: ansType.radioButton,
    choices: [
      {
        text: "届けてほしい",
        negative: [],
        positive: [plans.nutsAndCranberry, plans.cinnamonAndChocolate],
      },
      {
        text: "届けて欲しくない",
        negative: [],
        positive: [
          plans.nutsAndCinnamon,
          plans.saladAndCinnamon,
          plans.nutsAndSalad,
        ],
      },
    ],
  },
  {
    text: "甘じょっぱいグラノーラは\nお好きですか？",
    type: ansType.radioButton,
    choices: [
      {
        text: "届けてほしい",
        negative: [],
        positive: [
          plans.nutsAndCranberry,
          plans.saladAndCinnamon,
          plans.nutsAndSalad,
        ],
      },
      {
        text: "できればいらない",
        negative: [],
        positive: [
          plans.nutsAndCinnamon,
          plans.cinnamonAndCranberry,
          plans.cinnamonAndChocolate,
          plans.nutsAndChocolate,
        ],
      },
    ],
  },
  {
    text: "ランチにカフェで頼むなら？",
    type: ansType.buttonGroup,
    choices: [
      {
        text: "スパイスカレー",
        negative: [],
        positive: [plans.nutsAndCinnamon, plans.cinnamonAndCranberry],
      },
      {
        text: "フルーツと生ハムのサラダ",
        negative: [],
        positive: [
          plans.nutsAndChocolate,
          plans.nonSugar,
          plans.saladAndCinnamon,
          plans.nutsAndSalad,
        ],
      },
      {
        text: "ブリュレフレンチトースト",
        negative: [],
        positive: [
          plans.saladAndCinnamon,
          plans.nonSugar,
          plans.cinnamonAndChocolate,
          plans.nutsAndChocolate,
        ],
      },
      {
        text: "20種の野菜スープ",
        negative: [],
        positive: [
          plans.nutsAndCinnamon,
          plans.nonSugar,
          plans.saladAndCinnamon,
        ],
      },
    ],
  },
  {
    text: "朝一番に飲みたい飲み物は？",
    type: ansType.buttonGroup,
    choices: [
      { text: "白湯", negative: [], positive: [] },
      {
        text: "ホットコーヒー",
        negative: [],
        positive: [plans.nutsAndCranberry],
      },
      {
        text: "フレッシュオレンジジュース",
        negative: [],
        positive: [plans.saladAndCinnamon, plans.nutsAndSalad],
      },
      {
        text: "スパイシーホットアップル",
        negative: [],
        positive: [plans.nutsAndCinnamon, plans.cinnamonAndCranberry],
      },
    ],
  },
  {
    text: "暑いときに飲みたいドリンクは？",
    type: ansType.buttonGroup,
    choices: [
      {
        text: "アイスコーヒー",
        negative: [],
        positive: [plans.nutsAndCranberry],
      },
      {
        text: "アイスチャイラテ",
        negative: [],
        positive: [plans.nutsAndCinnamon, plans.cinnamonAndCranberry],
      },
      { text: "炭酸水", negative: [], positive: [plans.nonSugar] },
      {
        text: "自家製レモネード",
        negative: [],
        positive: [plans.saladAndCinnamon, plans.nutsAndSalad],
      },
    ],
  },
  {
    text: "この中で最も好みのお菓子は？",
    type: ansType.buttonGroup,
    choices: [
      {
        text: "カスタードシュークリーム",
        negative: [],
        positive: [plans.cinnamonAndChocolate, plans.nutsAndChocolate],
      },
      {
        text: "スパイシーナッツ",
        negative: [],
        positive: [plans.nutsAndCinnamon, plans.cinnamonAndCranberry],
      },
      {
        text: "コーヒーゼリー",
        negative: [],
        positive: [plans.nutsAndCranberry],
      },
      {
        text: "ラズベリーシャーベット",
        negative: [],
        positive: [plans.saladAndCinnamon, plans.nutsAndSalad],
      },
    ],
  },
];

export default questions;

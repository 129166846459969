import React from "react";
const nlRegex = /(\r\n|\r|\n)/g;
export const nl2br = (str) =>
  str.split(nlRegex).map((line, i) =>
    line.match(nlRegex) ? (
      <>
        <br key={i} />
      </>
    ) : (
      line
    )
  );
